<template>
    <div class="page">
      <ProfileClient :userId="$route.params.id"/>
    </div>
</template>

<script>
import ProfileClient from '@/components/clients/profile/ProfileClient';

export default {
  name: 'client-id',
  components: {
    ProfileClient,
  },
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
</style>
